import { FC } from 'react';

import { getStaticPrefix } from '@common/env';
import { useDevice } from '@hooks/deviceContext';
import { StoreButtonsRendering } from '@sitecore/types/manual/StoreButtons';
import { Image, Stack, VisuallyHidden } from '@sparky';

const StoreButtons: FC<StoreButtonsRendering> = ({ fields }) => {
  const { isIOS, isAndroid } = useDevice();
  const buttons = fields?.storeButtonsItem?.fields;

  return (
    <Stack gap="5" direction="row">
      {/* On Desktop always show the buttons */}
      {!isAndroid && buttons.iosButtonLink?.value?.href && (
        <Stack.Item>
          <a
            href={buttons.iosButtonLink?.value?.href}
            target={buttons.androidButtonLink?.value?.target}
            rel="noreferrer">
            <VisuallyHidden>{buttons.iosButtonLink?.value?.text}</VisuallyHidden>
            <Image
              alt="Apple App Store Badge"
              height={48}
              src={`${getStaticPrefix()}/images/eneco/apple-store-badge.png`}
            />
          </a>
        </Stack.Item>
      )}
      {!isIOS && buttons.androidButtonLink?.value?.href && (
        <Stack.Item>
          <a
            href={buttons.androidButtonLink?.value?.href}
            target={buttons.androidButtonLink?.value?.target}
            rel="noreferrer">
            <VisuallyHidden>{buttons.androidButtonLink?.value?.text}</VisuallyHidden>
            <Image
              alt="Google Play Store Badge"
              height={48}
              src={`${getStaticPrefix()}/images/eneco/google-play-badge.png`}
            />
          </a>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default StoreButtons;
